type SymbolMap = Record<
    string,
    {
        htmlCode: number
        optionalClass?: 'superscript' | 'subscript' | 'magnifyingGlass'
    }
>

// Format: 'unicode': { htmlCode: ..., optionalClass: ... }
export const SYMBOL_MAP: SymbolMap = {
    '030D': { htmlCode: 781, optionalClass: 'superscript' },
    '002A': { htmlCode: 42 },
    '02D6': { htmlCode: 726, optionalClass: 'subscript' },
    '030E': { htmlCode: 782, optionalClass: 'superscript' },
    '033E': { htmlCode: 830, optionalClass: 'superscript' },
    '0351': { htmlCode: 849, optionalClass: 'superscript' },
    '030A': { htmlCode: 778, optionalClass: 'superscript' },
    '02C6': { htmlCode: 710 },
    '033D': { htmlCode: 829, optionalClass: 'superscript' },
    '0342': { htmlCode: 834, optionalClass: 'superscript' },
    '034C': { htmlCode: 844, optionalClass: 'superscript' },
    '034B': { htmlCode: 843, optionalClass: 'superscript' },
    '00AE': { htmlCode: 174 },
    '0595': { htmlCode: 1429 },
    '05A0': { htmlCode: 1440, optionalClass: 'magnifyingGlass' },
    '0346': { htmlCode: 838, optionalClass: 'superscript' },
    '035B': { htmlCode: 859, optionalClass: 'superscript' },
    '2311': { htmlCode: 8977 },
    '02C5': { htmlCode: 709 },
    '0F1B': { htmlCode: 3867, optionalClass: 'subscript' },
    '0F1C': { htmlCode: 3868 },
    '0FCF': { htmlCode: 4047 },
    '10376': { htmlCode: 66422 },
    '10377': { htmlCode: 66423 },
    '10378': { htmlCode: 66424 },
    '10379': { htmlCode: 66425 },
    '2035': { htmlCode: 8245 },
    '2036': { htmlCode: 8246 },
    '2037': { htmlCode: 8247 },
    '1DF5': { htmlCode: 7669 },
}
